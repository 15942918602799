<template>
  <div class="F-news-pages">
    <!-- 列表 -->
      <div class="row">
          <div class="col-sm-8 col-xs-12">
              <div class="F-content-template">
                  <div class="F-list-news" v-for="(item, index) in list" :key="index" @click="handelDetail(item.linkUrl)">
                      <a href="javascript:;">
                          <div class="F-left fl"><img :src="item.images + '?x-oss-process=style/194_140'" alt=""></div>
                          <div class="F-right fr">
                              <div class="F-names-news">{{ item.title }}</div>
                              <div class="F-date">
                                  <img src="@/assets/images/date-ico.png" alt=""><span>{{ formatDate(item.addTime) }}</span>
                              </div>
                          </div>
                      </a>
                  </div>
              </div>
          </div>
          <!-- <div class="col-sm-4 hidden-xs F-news-btn">
              <div class="F-nav" @click="isShow = !isShow">学校新闻</div>
              <collapse-transition>
                <div class="F-datas-list" v-if="isShow">
                    <a href="javascript:;" v-for="(item, index) in newslist" :key="index" @click="handelDetail(item.linkUrl)">
                        <div class="F-list-news">
                            <p><img :src="item.images + '?x-oss-process=style/150_150'" alt=""></p>
                            <div class="F-texts">{{ item.title }}</div>
                        </div>
                    </a>
                </div>
              </collapse-transition>
          </div> -->
      </div>

      <div class="row">
          <pagination v-show="total>0" :total="total" :pageIndex.sync="queryParams.pageIndex" :pageSize.sync="queryParams.pageSize" @pagination="getList"></pagination>
      </div>
  </div>
</template>

<script>
import { getPage } from '@/api/index';
import pagination from '@/components/pagination';
import CollapseTransition from '@/utils/collapse-transition';

export default {
  name: 'list',
  data() {
    return {
      // 列表数据
      list: [],
      // 搜索条件
      queryParams: {
        pageIndex: 1,
        pageSize: 10
      },
      // 列表总条数
      total: 0,
      // 学校新闻显示隐藏
      isShow: false,
      // 学校新闻
      newslist: []
    }
  },
  watch: {
    '$route':function(val) {
      this.isShow = false;
      this.queryParams.pageIndex = 1;
      this.getList();
    }
  },
  components: {
    CollapseTransition, pagination
  },
  created() {
    this.getList();
    getPage('list-2', { pageIndex: 1, pageSize: 10 }).then( res => {
      this.newslist = res.data;
    })
  },
  methods: {
    getList() {
      getPage(this.$route.meta.url, this.queryParams).then( res => {
        this.list = res.data;
        this.total = res.total;
      })
    },
    // 进入详情
    handelDetail(url) {
      window.open(url);
    },
  },
}
</script>

