<template>
    <ul class="F-pagination">
       <li @click="changePage({val: 1}, 'index')" :class="page===1 ? 'disabled' : ''">首页</li> 
       <li @click="changePage(0, 'last')" :class="page===1 ? 'disabled hidden-xs' : 'hidden-xs'">上一页</li> 

       <li v-for="item in currentPageList" :key="item.key" :class="(page === item.val && item.val !== '...') ? 'active' : ''" @click="changePage(item, 'index')">{{ item.val }}</li> 

       <li @click="changePage(0, 'next')" :class="page===countindex ? 'disabled hidden-xs' : 'hidden-xs'">下一页</li> 
       <li @click="changePage({ val: countindex}, 'index')" :class="page===countindex ? 'disabled' : ''">末页</li> 
    </ul>
</template>

<script>

export default {
    data() {
        return {
            // 页码数量
            pageList: [],
            countindex: undefined,
            disableVisible: false,
            // 总数量
            totalCount: 0,
            // 当前展示的列表数量
            currentPageList: []
        }
    },
    props: {
        total: {
            required: true,
            type: Number
        },
        pageIndex: {
            type: Number
        },
        pageSize: {
            type: Number
        }
    },
    computed: {
        page: {
            get() {
                return this.pageIndex
            },
            set(val) {
                this.$emit('update:pageIndex', val)
            }
        },
        limit: {
            get() {
                return this.pageSize
            },
            set(val) {
                this.$emit('update:pageSize', val)
            }
        }
    },
    watch: {
        '$route':function(val) {
            
        },
        total(val) {
            this.totalCount = val;
            this.getPage();
        }
    },
    created() {
    },
    methods: {
        // 获取当前页码数量
        getPage() {
            this.countindex =  this.totalCount % this.pageSize > 0 ? Math.floor(this.totalCount / this.pageSize) + 1 : Math.floor(this.totalCount / this.pageSize);
            this.pageList = [];
            for (let i = 1; i <= this.countindex; i++) {
                this.pageList.push({ val: i, key: i })
            };
            if(this.page === 1) {
                this.getPageList(0, 5, false, this.pageList.length > 5 ? true : false);
            }
            
        },
        // 切换页码
        changePage(item, type) {
            if(item.val === '...') {
                if(item.key === -1) {
                    // 前面的省略号
                    let start = this.currentPageList[1].val - 6;
                    let end = this.currentPageList[1].val - 1;
                    this.getPageList(start, end, start <= 0 ? false : true, true);
                }else {
                    // 后面的省略号
                    let start = this.currentPageList[this.currentPageList.length - 2].val;
                    let end = this.currentPageList[this.currentPageList.length - 2].val+5;
                    this.getPageList(start, end, true, this.countindex <= end ? false : true);
                }
                
            }else {
                if(type === 'index') {
                    // 去指定页面
                    if(item.val === 1) {
                        // 首页
                        if(this.page !== 1) {
                            this.getPageList(0, 5, false, this.pageList.length > 5 ? true : false);
                        }
                    }else if(item.val === this.countindex) {
                        // 末页
                        if(this.page !== this.countindex) {
                            this.getPageList(this.countindex-5,this.countindex, this.pageList.length > 5 ? true : false, false, item.val);
                        }
                    }else {
                        this.getPageList(-999, item.val);
                    }
                }else if(type === 'last') {
                    // 上一页
                    if(this.page !== 1) {
                        // 当前不是第一页时
                        let flag = this.currentPageList.some( i => i.val === this.page - 1);
                        if(flag) {
                            // 不需要改变页码数组
                            this.getPageList(-999, this.page - 1);
                        }else {
                            // 数组需要往前移动一组并选中最后一项页码
                            let start = this.currentPageList[1].val - 6;
                            let end = this.currentPageList[1].val - 1;
                            this.getPageList(start, end, start <= 0 ? false : true, true, this.page - 1);
                        };
                    };
                }else if(type === 'next') {
                    // 下一页
                    if(this.countindex !== this.page) {
                        // 当前不是最后一页时
                        let flag = this.currentPageList.some( i => i.val === this.page + 1);
                        if(flag) {
                            // 不需要改变页码数组
                            this.getPageList(-999, this.page + 1);
                        }else {
                            // 数组需要往后移动一组并选中第一项页码
                            let start = this.currentPageList[this.currentPageList.length - 2].val;
                            let end = this.currentPageList[this.currentPageList.length - 2].val+5;
                            this.getPageList(start, end, true, this.countindex <= end ? false : true, this.page+1);
                        };
                    };
                };
            }
            
        },
        // 获取对应的页码数组和当前页码
        getPageList(start, end, last, next, index) {
            // start: 开始下标, end: 结束下标, last: 前面是否加省略号, next: 后面是否加省略号, index: 指定选中的页面
            if(start === -999) {
                this.page = end;
            }else {
                this.currentPageList = this.pageList.slice(start,end);
                if(start < 0) {
                    // 点击了上一个省略号后前一组数据数量<5时直接取下标0-5的数据
                    this.currentPageList = this.pageList.slice(0, 5);
                }
                if(last) {
                    this.currentPageList.unshift({ val: '...', key: -1 });
                    this.page = index ? index : this.currentPageList[1].val;
                }else {
                    this.page = index ? index : this.currentPageList[0].val;
                }
                if(next) {
                    this.currentPageList.push({ val: '...', key: -2 });
                };
            }
            this.$emit('pagination', { pageIndex: this.page, pageSize: this.limit })
        }
    },
}
</script>