<template>
  <div class="F-content-template">
    <!-- 多内容tab -->
    <div class="F-tab">
        <template v-for="(item, index) in list">
            <a :key="index" :class="active === index ? 'F-tab-list fl F-aver' : 'F-tab-list fl'" href="javascript:;" @click="changeTab(item, index)">{{ item.title }}</a>
        </template>
    </div>

    <div class="multiplePage" v-html="content"></div>
  </div>
</template>

<script>
import { getPage } from '@/api/index';

export default {
  name: 'multiplePage',
  data() {
    return {
      list: [],
      queryParams: {
        pageIndex: 1,
        pageSize: 10
      },
      active: 0,
      content: {}
    }
  },
  watch: {
    '$route':function(val) {
      this.active = 0;
      this.getList();
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getPage(this.$route.meta.url).then( res => {
        this.list = res.data;
        this.content = this.list[0].content;
      })
    },
    // 切换tab
    changeTab(item, index) {
      this.active = index;
      this.content = item.content;
    }
  },
}
</script>

<style lang="less">
.multiplePage {
  h1 {
    margin-bottom: 10px;
  }
  a {
    border-bottom: 1px dashed #337AB7;
    font-size: 16px;
  }
  p,
  div {
    font-size: 16px;
    line-height: 180%;
  }
  @media (max-width: 767px) {
    /*屏幕小于 767 px*/
    img,
    p,
    span {
      max-width: 100%;
      font-size: 15px;
    }
  }
}
</style>

