<template>
  <div class="F-content-template">
    <!-- 名师团队 -->
    <div class="row">
        <div class="col-md-3 col-sm-4 col-xs-6" v-for="(item, index) in list" :key="index">
            <div class="F-list">
                <p class="F-image"><img class="F-portrait-img" :src="item.images + '?x-oss-process=style/150_150'" alt=""></p>
                <p class="F-names">{{ item.title }}</p>
                <div class="F-value">
                    {{ item.summary }}
                </div>
                <div class="F-more" @click="handleDetail(item)"><a href="javascript:;">更多 ></a></div>
            </div>
        </div>
    </div>
    <div class="row">
        <pagination v-show="total>0" :total="total" :pageIndex.sync="queryParams.pageIndex" :pageSize.sync="queryParams.pageSize" @pagination="getList"></pagination>
    </div>

  </div>
</template>

<script>
import { getPage } from '@/api/index';
import pagination from '@/components/pagination';

export default {
  name: 'list',
  data() {
    return {
      // 列表数据
      list: [],
      // 搜索条件
      queryParams: {
        pageIndex: 1,
        pageSize: 12
      },
      // 列表总条数
      total: 0
    }
  },
  watch: {
    '$route':function(val) {
      this.queryParams.pageIndex = 1;
      this.getList();
    }
  },
  components: {
    pagination
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getPage(this.$route.meta.url, this.queryParams).then( res => {
        this.list = res.data;
        this.total = res.total;
      })
    },
    // 详情
    handleDetail(item) {
      this.$router.push({ path: '/detail', query: { id: item.newsId, meta: this.$route.meta, title: item.title } })
    }
  },
}
</script>

<style scoped src="@/assets/styles/teacher.css"></style>


