<template>
<!-- 视频分页 -->
  <div class="F-content-template">
      <div class="row flexRow">
          <div class="col-md-3 col-sm-4 col-xs-6" v-for="(item, index) in list" :key="index">
              <a class="f-aver" href="javascript:;" @click="handleDetail(item)">
                  <div class="f-image">
                      <img :src="item.images + '?x-oss-process=style/150_150'" alt="">
                  </div>
                  <div class="f-video-text">
                      {{ item.title }}
                  </div>
              </a>
          </div>
      </div>

      <div class="row">
        <pagination v-show="total>0" :total="total" :pageIndex.sync="queryParams.pageIndex" :pageSize.sync="queryParams.pageSize" @pagination="getList"></pagination>
      </div>

  </div>
</template>

<script>
import { getPage } from '@/api/index';
import pagination from '@/components/pagination';

export default {
  name: 'list',
  data() {
    return {
      // 列表数据
      list: [],
      // 搜索条件
      queryParams: {
        pageIndex: 1,
        pageSize: 12
      },
      // 列表总条数
      total: 0
    }
  },
  watch: {
    '$route':function(val) {
      this.queryParams.pageIndex = 1;
      this.getList();
    }
  },
  components: {
    pagination
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getPage(this.$route.meta.url, this.queryParams).then( res => {
        this.list = res.data;
        this.total = res.total;
      })
    },
    // 详情
    handleDetail(item) {
      this.$router.push({ path: '/detail', query: { id: item.newsId, meta: this.$route.meta, title: item.title } })
    }
  },
}
</script>

<style>
    .f-image {
        width: 100%;
    }
    .f-image img {
        width: 100%;
        height: 110px;
    }
    .f-video-text {
        padding-top: 10px;
        text-align: justify;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 20px;
    }
    .f-aver {
        color: #333;
    }
    .f-aver:hover {
        color: #333;
    }
    .flexRow {
      display: flex;
      flex-wrap: wrap;
    }
</style>

