<template>
  <div class="F-content-template pageContent" v-html="content">
  </div>
</template>

<script>
import { getPage } from '@/api/index';

export default {
  name: 'page',
  data() {
    return {
      content: ''
    }
  },
  watch: {
    '$route':function(val) {
      this.getInfo();
    }
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      getPage(this.$route.meta.url).then( res => {
        this.content = res.data.content;
      })
    }
  },
}
</script>

<style lang="less">
.pageContent {
  a {
    border-bottom: 1px dashed #337AB7;
    font-size: 16px;
  }
  p,
  div {
    font-size: 16px;
    line-height: 180%;
  }
  @media (max-width: 767px) {
    /*屏幕小于 767 px*/
    img,
    p,
    span {
      max-width: 100%;
      font-size: 15px;
    }
  }
  
}
</style>
