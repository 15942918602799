<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-xs-12 col-md-8">
          <div class="F-content-template">
              <div class="panel-group wrap" id="accordion" role="tablist" aria-multiselectable="true">
                  <div class="panel" v-for="(item, index) in list" :key="index">
                      <div :class="(active===index && isShow) ? 'panel-heading active' : 'panel-heading'" role="tab" @click="handleActive(index)">
                          <h4 class="panel-title">
                              <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  {{ item.title }}
                              </a>
                          </h4>
                      </div>
                      <collapse-transition>
                      <div class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne" v-if="(active === index) && isShow">
                          <div class="panel-body" v-html="item.content"></div>
                      </div>
                      </collapse-transition>
                  </div>
              </div>
          </div>
          
      </div>
      
      <div class="col-sm-12 col-xs-12 col-md-4">
          <div class="F-recruitment">
              <p class="F-email">招聘邮箱：</p>
              <div class="F-nei-email-box">
                  <p class="F-domestic">国内部：</p>
                  <div class="F-em">
                      <!-- <div class="F-code-em fl" id="text1">hdsy2020hr@haidegroup.com</div> -->
                      <input type="text" class="F-code-em fl" readonly="readonly" id="text1" value="hdsy2020hr@haidegroup.com" />
                      <textarea id="input">fuzhi</textarea>
                      <div class="F-copy fr hidden-xs" style="z-index:99" @click="copytext('text1')">复制</div>
                      <span class="clearfix"></span>
                  </div>
              </div>
              <div class="F-nei-email-box">
                  <p class="F-domestic" >国际部：</p>
                  <div class="F-em">
                      <!-- <div class="F-code-em fl" id="text2">hbsd2020hr@haidegroup.com</div> -->
                      <input type="text" class="F-code-em fl" readonly="readonly" id="text2" value="hdsy2020hr@haidegroup.com" />
                      <div class="F-copy fr hidden-xs" style="z-index:99" @click="copytext('text2')">复制</div>
                      <span class="clearfix"></span>
                  </div>
              </div>
          </div>
      </div>
      
    </div>
      <div class="row">
        <pagination v-show="total>0" :total="total" :pageIndex.sync="queryParams.pageIndex" :pageSize.sync="queryParams.pageSize" @pagination="getList"></pagination>
      </div>
  </div>
  
</template>

<script>
import { getPage } from '@/api/index';
import pagination from '@/components/pagination';
import CollapseTransition from '@/utils/collapse-transition';

export default {
  name: 'list',
  data() {
    return {
      // 列表数据
      list: [],
      // 搜索条件
      queryParams: {
        pageIndex: 1,
        pageSize: 10
      },
      // 列表总条数
      total: 0,
      // 显示隐藏
      active: 0,
      isShow: true
    }
  },
  watch: {
    '$route':function(val) {
      this.queryParams.pageIndex = 1;
      this.getList();
    }
  },
  components: {
    CollapseTransition, pagination
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getPage(this.$route.meta.url, this.queryParams).then( res => {
        this.list = res.data;
        this.total = res.total;
      })
    },
    handleActive(index) {
      if(this.active === index) {
        this.isShow = !this.isShow
      }else {
        this.active = index;
        this.isShow = true;
      };
    },
    // 复制
    copytext(index) {
      let text = document.getElementById(index).innerText;
      let input = document.getElementById("input");
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      alert("复制成功")
      return false;
    }
  },
}
</script>

<style scoped src="@/assets/styles/jobList.css"></style>

<style scoped>
#input {position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;}
</style>
