import { render, staticRenderFns } from "./multiplePage.vue?vue&type=template&id=dadc4c04&"
import script from "./multiplePage.vue?vue&type=script&lang=js&"
export * from "./multiplePage.vue?vue&type=script&lang=js&"
import style0 from "./multiplePage.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports